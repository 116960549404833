<script>
	import styles from './Text.module.css';

	/** @type {string} */
	export let as = 'span';

	/** @type {string | undefined} */
	export let align = undefined;

	/** @type {string} */
	let className = '';
	export { className as class };

	/** @type {true | undefined} */
	export let ellipsis = undefined;

	/** @type {true | undefined} */
	export let nowrap = undefined;

	/**
	 * Size of component
	 * @type {Size}
	 */
	export let size = 'md';

	/** @type {'normal' | 'subtle' | 'negative'} */
	export let tone = 'normal';

	/** @type {'normal' | 'semibold'} */
	export let weight = 'normal';
</script>

<svelte:element
	this={as}
	class="{styles.text} {className}"
	data-ellipsis={ellipsis}
	data-nowrap={nowrap}
	data-size={size}
	data-tone={tone}
	data-weight={weight}
	style:text-align={align}
	{...$$restProps}><slot /></svelte:element
>
